* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}


@keyframes float {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0px);
  }
}

.shape {
  animation: float 3s ease-in-out infinite;
}
.bg-wrapperDark {
  background-attachment: fixed; /* Ensures the background stays fixed */
  background-size: cover;
  background-position: center;
}


/* Example media query for Safari */
@media only screen and (max-width: 600px) {
  .tabs-header {
    flex-direction: row !important;
  }
}





