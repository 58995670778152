@import url("https://fonts.googleapis.com/css2?family=Russo+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Petit+Formal+Script&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .pushable {
    background: hsl(208deg 100% 32%);
    border-radius: 25px;
    border: none;
    position: relative;
    color: #fff;
    text-transform: uppercase;
    font-family: "Inter";
    padding: 0px;
    box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.12);
    cursor: pointer;
    outline-offset: 2px;
  }
  .front {
    display: block;
    padding: 18px 20px;
    border-radius: 25px;
    font-size: 1.15rem;
    background: #4169e1;
    color: white;
    font-family: "Inter";
    transform: translateY(-6px);
  }
  .front2 {
    display: block;
    padding: 18px 20px;
    border-radius: 25px;
    font-size: 1.15rem;
    background: hsl(202 99% 38%);
    color: white;
    font-family: "Inter";
    transform: translateY(-6px);
  }
  .pushable:active .front {
    transform: translateY(-2px);
  }
  .transform {
    transform: translateY(-200px);
  }

  .header {
    animation: bounce 4s linear infinite;
  }
  @keyframes bounce {
    0% {
      transform: translateY(-20px);
    }
    50% {
      transform: translateY(20px);
    }
    100% {
      transform: translateY(-20px);
    }
  }
  @media (max-width: 639px) {
    .front {
      display: block;
      padding: 14px 12px;
      border-radius: 25px;
      font-size: 1.15rem;
      background: #4169e1;
      color: white;
      font-family: "Inter";
      transform: translateY(-6px);
    }
  }
}
